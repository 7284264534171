:root {
    --amplify-components-fieldcontrol-font-size: 20px;
  }

.app {
    text-align: center;
    background-image: inherit;
    
    &-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
        background-color: #282c34;
        color: white;
        font-size: calc(10px + 2vmin);

        span {
            position: relative;
            bottom: 1px;
            margin-left: 10px;
        }
    }

    .sign-out-link {
        margin-top: 20px;
        padding: 5px;
        border: 0;
        border-radius: 0;
        background: #61dafb;
        text-transform: uppercase;
        font-size: 16px;
        cursor: pointer;
    }
}

.amplify-autocomplete-option {
    font-size: 18px; /* replace with your desired font size */
    width: 100%;
  }

